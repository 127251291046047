import React, { Component } from 'react'

import Routes from './Routes'

import CKLogo from './ck-logo-dark.png'
import './App.css'

class App extends Component {
  render () {
    return (
      <div>
        <div className='ck-header'>
          <img src={CKLogo} width={140} alt='CoderKit.co' />
        </div>
        <Routes />
      </div>
    )
  }
}

export default App
