import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';
import Analytics from 'react-router-ga';

import asyncComponent from "./components/AsyncComponent";
import ScrollToTop from "./components/ScrollToTop";

const AsyncWelcome = asyncComponent(() => import("./pages/Welcome"));
const AsyncNotFound = asyncComponent(() => import("./pages/NotFound"));
const AsyncRange = asyncComponent(() => import("./pages/Range"));

/**
const AsyncNewStarter = asyncComponent(() => import("./pages/NewStarter"));
const AsyncContact = asyncComponent(() => import("./pages/Contact"));
const AsyncEmployee = asyncComponent(() => import("./pages/Employee"));
const AsyncBusiness = asyncComponent(() => import("./pages/Business"));
const AsyncOneOffStep1 = asyncComponent(() => import("./pages/OneOff/Step1"));
const AsyncOneOffStep2 = asyncComponent(() => import("./pages/OneOff/Step2"));
const AsyncOneOffStep3 = asyncComponent(() => import("./pages/OneOff/Step3"));
const AsyncOneOffStep4 = asyncComponent(() => import("./pages/OneOff/Step4"));
const AsyncOneOffStep5 = asyncComponent(() => import("./pages/OneOff/Step5"));
**/

export default ({ childProps }) =>
  <BrowserRouter>
    <Analytics id="UA-1182785-21">
      <ScrollToTop>

        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route
            path="/"
            exact
            component={AsyncWelcome}
            props={childProps}
          />
          
          <Route
            path="/range"
            exact
            component={AsyncRange}
            props={childProps}
          />

        {/**
          <Route
            path="/employee"
            exact
            component={AsyncEmployee}
            props={childProps}
          />

          <Route
            path="/business"
            exact
            component={AsyncBusiness}
            props={childProps}
          />
          <Route
            path="/content/new-starters"
            exact
            component={AsyncNewStarter}
            props={childProps}
          />
          <Route
            path="/contact"
            exact
            component={AsyncContact}
            props={childProps}
          />
          <Route
            path="/buy/one-off"
            exact
            component={AsyncOneOffStep1}
            props={childProps}
          />
          <Route
            path="/buy/one-off/2"
            exact
            component={AsyncOneOffStep2}
            props={childProps}
          />
          <Route
            path="/buy/one-off/3"
            exact
            component={AsyncOneOffStep3}
            props={childProps}
          />
          <Route
            path="/buy/one-off/4"
            exact
            component={AsyncOneOffStep4}
            props={childProps}
          />
          <Route
            path="/buy/one-off/confirm"
            exact
            component={AsyncOneOffStep5}
            props={childProps}
          />
        **/}

          <Route component={AsyncNotFound} />
        </AnimatedSwitch>
      </ScrollToTop>
      </Analytics>
  </BrowserRouter>
;
